import _ from "lodash";

import { IGenericModelFields } from "../IGenericFields";
import { WorkspaceIntegrationType } from "./IWorkspace";

export interface IWorkspaceIntegrationCredentials extends IGenericModelFields {
    workspaceId: string;
    integrationType: WorkspaceIntegrationType;
    tokens: {
        access_token: string;
        instance_url: string;
        scope: string;
        token_type: string;
        id: string;
    };
}
