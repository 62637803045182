import { IPlayerBranding } from "./ITeam";

export interface ITheme {
    isBuiltIn: boolean;
    id: string;
    name: string;
    showLogo: boolean;
    logoScale: number;
    logoPosition: "left" | "right";
    logoOffset: number;
    showMessage: boolean;
    showPageNum: boolean;
    logo: string | null;
    logo_dark: string | null;
    showFooterByDefault: boolean | null;
    palette_name: string;
    defaultSlideColor: string;
    defaultBackgroundColor: string;
    styleFonts: number;
    styleFontWeight: string;
    styleElementStyle: string;
    styleEffect: string;
    styleDesign: number;
    styleDecoration: string;
    styleHeaderAlignment: string;
    styleShape: string;
    styleColor: string;
    styleBackground: string;
    styleTitleFont: string;
    styleBodyFont: string;
    styleWeight: string;
    styleTitleSlide: string;
    fontScale: number;
    iconStyle: string;
    cjkFont: string;
    colors: {
        accent1: string;
        accent2: string;
        accent3: string;
        background_accent: string;
        background_dark: string;
        background_light: string;
        chart1: string;
        chart2: string;
        chart3: string;
        chart4: string;
        chart5: string;
        chart6: string;
        primary_dark: string;
        primary_light: string;
        secondary_dark: string;
        secondary_light: string;
        theme: string;
        hyperlink: string;
    };
    fontHeaderFontId: string;
    fontHeaderWeight: number;
    fontHeaderLetterSpacing: number;
    fontHeaderLineHeight: number;
    fontHeaderScaling: number;
    fontHeaderTextTransform: string;
    fontTitleFontId: string;
    fontTitleWeight: number;
    fontTitleLetterSpacing: number;
    fontTitleLineHeight: number;
    fontTitleScaling: number;
    fontTitleTextTransform: string;
    fontBodyFontId: string;
    fontBodyWeight: number;
    fontBodyLetterSpacing: number;
    fontBodyLineHeight: number;
    fontBodyScaling: number;
    fontBodyTextTransform: string;
    playerBranding?: IPlayerBranding;
}
